var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('div',{staticClass:"px-2 pt-2 pb-0"},[_c('feather-icon',{staticClass:"mb-1 home__icon cursor-pointer",attrs:{"icon":"HomeIcon","size":"40"},on:{"click":function () { return _vm.$router.push({ name: 'auth-login' }); }}})],1),_c('b-row',[_c('b-col',{staticClass:"d-md-block d-none"},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"px-4 py-2"},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","variant":"transparent"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner'),_c('p',{staticClass:"mt-1"},[_vm._v("Registrando usuario")])],1)]},proxy:true}])},[_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"layout":"horizontal","finish-button-text":_vm.loading
              ? 'Procesando...'
              : _vm.$route.meta.isEstablishmentCustomer !== true
              ? 'Confirmar'
              : _vm.$route.meta.isEstablishmentCustomer === true &&
                _vm.addWalleatToCustomer === false
              ? 'Confirmar'
              : 'Siguiente',"next-button-text":"Siguiente","back-button-text":"Regresar","shape":"square","step-size":"sm"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Nombre","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Nombre del usuario o negocio")])]),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"v-ammount"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-input-group',{},[_c('b-form-input',{attrs:{"id":"v-ammount","state":errors.length > 0 ? false : null,"placeholder":"José López","size":"lg","type":"text"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.$route.meta.isEstablishmentCustomer === true)?_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" ¿Cuánto es lo máximo que le vas a fiar a este cliente? ")])]),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"v-loanBalanceLimit"}},[_c('validation-provider',{attrs:{"name":"Loan Balance Limit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-input-group',{},[_c('b-form-input',{attrs:{"id":"v-loanBalanceLimit","state":errors.length > 0 ? false : null,"placeholder":"100","size":"lg","type":"number"},model:{value:(
                            _vm.user.loan_limit_attributes.balance_loan_limit
                          ),callback:function ($$v) {_vm.$set(_vm.user.loan_limit_attributes, "balance_loan_limit", $$v)},expression:"\n                            user.loan_limit_attributes.balance_loan_limit\n                          "}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2177858235)})],1)],1)],1):_vm._e()],1)],1),_c('tab-content',{attrs:{"title":"Correo y número celular","before-change":_vm.validationCelForm}},[_c('validation-observer',{ref:"accountCelRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"my-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Teléfono celular")])]),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"v-loginFieldPhone"}},[_c('validation-provider',{attrs:{"name":"El celular es necesario para validar su cuenta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"v-loginFieldPhone","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.user.phone_number),callback:function ($$v) {_vm.$set(_vm.user, "phone_number", $$v)},expression:"user.phone_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"my-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Correo electrónico")])]),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"v-loginFieldEmail"}},[_c('validation-provider',{attrs:{"name":"El correo electrónico es necesario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"v-loginFieldEmail","state":errors.length > 0 ? false : null,"placeholder":"email","size":"lg"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),(_vm.$route.meta.isEstablishmentCustomer)?_c('tab-content',{attrs:{"title":"Agregar tarjeta Walleat"}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"mb-1 d-flex mt-2"},[_c('b-form-group',{attrs:{"label":"¿Deseas vincular una tarjeta Walleat al cliente?","label-for":"is-weighted","label-size":"lg"}},[_c('b-button-group',{staticClass:"d-flex align-items-center",attrs:{"id":"add-walleat-card"},model:{value:(_vm.addWalleatToCustomer),callback:function ($$v) {_vm.addWalleatToCustomer=$$v},expression:"addWalleatToCustomer"}},[_c('b-button',{attrs:{"variant":_vm.addWalleatToCustomer ? 'success' : 'outline-success'},on:{"click":function($event){return _vm.handleCustomerWalleatButton(true)}}},[_vm._v("Sí")]),_c('b-button',{attrs:{"variant":_vm.addWalleatToCustomer === false
                            ? 'danger'
                            : 'outline-danger'},on:{"click":function($event){return _vm.handleCustomerWalleatButton(false)}}},[_vm._v("No")])],1)],1)],1)])],1)],1):_vm._e(),_c('tab-content',{attrs:{"title":"Confirmar"}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"mb-1"},[_c('p',{staticClass:"mb-0 text-muted"},[_vm._v("Nombre:")]),_c('p',{staticClass:"display-5"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('p',{staticClass:"mb-0 text-muted"},[_vm._v("Correo electrónico:")]),_c('p',{staticClass:"display-5"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]),_c('p',{staticClass:"mb-0 text-muted"},[_vm._v("Celular:")]),_c('p',{staticClass:"display-5"},[_vm._v(" "+_vm._s(_vm.user.phone_number)+" ")]),(_vm.$route.meta.isEstablishmentCustomer === true)?_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(" Límite de crédito: ")]):_vm._e(),(_vm.$route.meta.isEstablishmentCustomer === true)?_c('p',{staticClass:"display-5"},[_vm._v(" "+_vm._s(_vm.user.loan_limit_attributes.balance_loan_limit)+" ")]):_vm._e()])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }